<template>
    <div>
        <Table :loading="loading" :data="tableData" :height="500" :columns="columns"></Table>

        <!-- 详情 -->
        <Detailsfrom ref="details_from" :modifiable="details.status !== '已归档'" :code="orgCode" @on-refresh="$emit('getList')"></Detailsfrom>
    </div>
</template>

<script>
import ElemLook from "../../residentdatabase/components/ElemLook.vue"
import Detailsfrom from "../../residentdatabase/children/DetailsFroms.vue"

export default {
    components: {
        Detailsfrom,
    },
    props: {
        tableData: Object,
        houseCode: String,
        loading: Boolean,
        orgCode: String,
    },
    data() {
        return {
            details: {},
            columns: [
                {
                    title: "序号",
                    type: "index",
                    minWidth: 80,
                    align: "center",
                    fixed: "left",
                },
                {
                    title: "房号",
                    key: "houseNum",
                    minWidth: 120,
                    align: "center",
                },
                {
                    title: "姓名",
                    key: "userName",
                    minWidth: 120,
                    align: "center",
                },
                {
                    title: "联系电话",
                    key: "mobile",
                    minWidth: 150,
                    align: "center",
                },
                {
                    title: "证件号码",
                    key: "idNum",
                    minWidth: 200,
                    align: "center",
                },
                {
                    title: "所在详细区域",
                    key: "addr",
                    minWidth: 300,
                    align: "center",
                },
                {
                    title: "是否激活",
                    key: "activeStatus",
                    minWidth: 120,
                    align: "center",
                },
                {
                    title: "来源",
                    key: "dataSource",
                    minWidth: 170,
                    align: "center",
                },
                {
                    title: "数据状态",
                    key: "status",
                    minWidth: 120,
                    align: "center",
                },
                {
                    fixed: "right",
                    title: "操作",
                    width: 250,
                    align: "center",
                    render: (h, params) => {
                        return h("div", [
                            h(ElemLook, {
                                style: "margin-right:8px",
                                props: {
                                    row: params.row,
                                    id: params.row.ubaseId,
                                    source: "ubase",
                                },
                                on: {
                                    "on-change": res => {
                                        Object.assign(params.row, res.value)
                                    },
                                },
                            }),
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "success",
                                        size: "small",
                                    },
                                    style: "margin:0 3px",
                                    nativeOn: {
                                        click: () => {
                                            this.details = params.row
                                            this.$refs.details_from.display(params.row.ubaseId)
                                        },
                                    },
                                },
                                "编辑"
                            ),
                            // h(
                            //     "Dropdown",
                            //     {
                            //         props: {
                            //             transfer: true,
                            //         },
                            //         style: "margin:0 3px",
                            //     },
                            //     [
                            //         h(
                            //             "Button",
                            //             {
                            //                 props: {
                            //                     type: "info",
                            //                     size: "small",
                            //                     icon: "ios-arrow-down",
                            //                 },
                            //             },
                            //             "更多"
                            //         ),
                            //         h(
                            //             "DropdownMenu",
                            //             {
                            //                 slot: "list",
                            //             },
                            //             [
                            //                 h(
                            //                     "DropdownItem",
                            //                     {
                            //                         nativeOn: {
                            //                             click: () => {
                            //                                 this.deleteUser(params.row.ubaseId)
                            //                             },
                            //                         },
                            //                         style: {
                            //                             textAlign: "center",
                            //                         },
                            //                     },
                            //                     "删除"
                            //                 ),
                            //                 h(
                            //                     "DropdownItem",
                            //                     {
                            //                         nativeOn: {
                            //                             click: () => {
                            //                                 this.$refs.operation_record.display(params.row.ubaseId)
                            //                             },
                            //                         },
                            //                         style: {
                            //                             textAlign: "center",
                            //                         },
                            //                     },
                            //                     "查看修改记录"
                            //                 ),
                            //                 params.row.status === "已归档"
                            //                     ? null
                            //                     : h(
                            //                           "DropdownItem",
                            //                           {
                            //                               nativeOn: {
                            //                                   click: () => {
                            //                                       if (params.row.status === "已迁出") {
                            //                                           this.onMoveInUser(params.row.ubaseId)
                            //                                       } else {
                            //                                           this.moveOutUbaseId = params.row.ubaseId
                            //                                           this.isDisplayMoveOutUser = true
                            //                                       }
                            //                                   },
                            //                               },
                            //                               style: {
                            //                                   textAlign: "center",
                            //                               },
                            //                           },
                            //                           params.row.status === "已迁出" ? "迁入" : "迁出"
                            //                       ),
                            //                 params.row.status === "已归档"
                            //                     ? null
                            //                     : h(
                            //                           "DropdownItem",
                            //                           {
                            //                               nativeOn: {
                            //                                   click: () => {
                            //                                       this.archiveUbaseId = params.row.ubaseId
                            //                                       this.isDisplayArchiveUser = true
                            //                                   },
                            //                               },
                            //                               style: {
                            //                                   textAlign: "center",
                            //                               },
                            //                           },
                            //                           "归档"
                            //                       ),
                            //             ]
                            //         ),
                            //     ]
                            // ),
                        ])
                    },
                },
            ],
        }
    },
    methods: {
        //  修改详情
        alterDetails(userId, custGlobalId, familyId, familyMemberId, orgCode) {
            this.$router.push({
                path: `/linehomeaddform?isEdit=true&userId=${userId}&custGlobalId=${custGlobalId}&familyId=${familyId}&familyMemberId=${familyMemberId}&orgCode=${orgCode}`,
                query: { isLineHome: this.$route.query.isLineHome ? this.$route.query.isLineHome : "", page: this.$route.query.page ? this.$route.query.page : "", nameover: this.$route.query.nameover, build: "true", communityCode: this.$route.query.communityCode },
            })
        },
        remove(row) {
            if (row.familyMemberId) {
                this.$Modal.confirm({
                    title: "温馨提示",
                    content: "<p>确认删除数据</p>",
                    cancelText: "取消",
                    okText: "确认",
                    onOk: () => {
                        this.$post("/syaa/pc/sy/user/online/home/delFamilyMember", {
                            familyMemberId: row.familyMemberId,
                        }).then(res => {
                            if (res.code === "200") {
                                this.$Message.success({ content: "删除成功", background: true })
                                this.$emit("getList", this.houseCode)
                            } else {
                                this.$Message.error({ content: "删除失败", background: true })
                            }
                        })
                    },
                })
            }
        },
    },
}
</script>

<style lang="less" scoped></style>
